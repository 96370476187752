import React from 'react';
import cx from 'clsx';

interface Props {
  tabs: string[];
  active: string;
  onClick: (value: string) => void;
}

export default function Tabs(props: Props) {
  const { active, onClick, tabs } = props;

  return (
    <div className="flex flex-row mb-8 w-full border-b border-gray-300">
      {tabs.map((tab) => {
        return (
          <button
            className={cx(
              'mr-5 focus:text-brand-400 hover:text-brand-400 text-gray-600 leading-10 border-b-4 border-transparent cursor-pointer',
              active === tab ? 'border-brand-400 text-brand-400' : '',
            )}
            key={tab}
            onClick={() => onClick(tab)}
          >
            {tab}
          </button>
        );
      })}
    </div>
  );
}
