import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faArrowLeft,
  faBoxes,
  faBuilding,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCopy,
  faEllipsisV,
  faEnvelope,
  faGlobeEurope,
  faGripLinesVertical,
  faHandshake,
  faHome,
  faIdCard,
  faInfoCircle,
  faLanguage,
  faMapMarkerAlt,
  faMobileAlt,
  faPencilAlt,
  faPhone,
  faPlus,
  faPowerOff,
  faSave,
  faSlidersH,
  faSpinner,
  faTimes,
  faTrashAlt,
  faUser,
  faUsers,
  faUserShield,
  faUserTie,
  faVideo,
} from '@fortawesome/free-solid-svg-icons';

export const OPTIONS = [
  'building',
  'caret-down',
  'caret-up',
  'check',
  'check-circle',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'copy',
  'ellipsis-v',
  'envelope',
  'globe-europe',
  'handshake',
  'home',
  'id-card',
  'language',
  'map-marker-alt',
  'mobile-alt',
  'pencil-alt',
  'phone',
  'plus',
  'times',
  'user',
  'user-tie',
  'video',
];

library.add(
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faPencilAlt,
  faIdCard,
  faUser,
  faEnvelope,
  faPhone,
  faVideo,
  faMobileAlt,
  faGlobeEurope,
  faLanguage,
  faMapMarkerAlt,
  faPlus,
  faSpinner,
  faUserTie,
  faHome,
  faBuilding,
  faHandshake,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faTimes,
  faEllipsisV,
  faCopy,
  faInfoCircle,
  faTrashAlt,
  faSave,
  faCog,
  faUsers,
  faAlignLeft,
  faBoxes,
  faPowerOff,
  faSlidersH,
  faUserShield,
  faChevronUp,
  faGripLinesVertical,
);
