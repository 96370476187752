import React, { useCallback } from 'react';

import { useCategoryContext } from '../context/CategoryContext';
import Label from '../fields/_common/label';
import SelectField from '../fields/SelectField';

type Props = {
  label: string;
  name: string;
};

function CategorySelector({ label, name }: Props) {
  const { categories, category, onChangeCategory } = useCategoryContext();

  const onChange = useCallback(
    (categoryId: string) => {
      const newCategory = categories.find(
        (category) => category.id === categoryId,
      );

      if (newCategory !== undefined) {
        onChangeCategory(newCategory);
      }
    },
    [categories, onChangeCategory],
  );

  return (
    <>
      <div className="hidden">
        <Label className="leading-none" htmlFor={name}>
          {label}
        </Label>
      </div>
      <SelectField
        name={name}
        nullable={false}
        onChange={onChange}
        value={category?.id}
      >
        {categories.map((category) => (
          <option key={category.id} value={category.id}>
            {category.label}
          </option>
        ))}
      </SelectField>
    </>
  );
}

export default CategorySelector;
