import React, { PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import styled from 'styled-components';

import { Label } from './_common/label';
import { InputFieldProps } from './_common/types';

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.BRAND};
  width: 1.25rem !important;
  height: 1.25rem !important;
  position: absolute;
  right: 1rem;
  top: 0.8rem;
  pointer-events: none;
`;

const PlaceholderRight = styled.span`
  color: ${({ theme }) => theme.colors.BRAND};
  position: absolute;
  right: 2.5rem;
  pointer-events: none;
`;

interface Props extends PropsWithChildren<InputFieldProps<string>> {
  name: string;
  nullable?: boolean;
  nullableLabel?: string;
  label?: string;
  placeholderRight?: string;
}

export default function SelectField({
  children,
  disabled = false,
  label,
  name,
  nullable = true,
  nullableLabel,
  onBlur,
  onChange,
  onMouseEnter,
  onMouseLeave,
  placeholderRight,
  value,
}: Props) {
  const { t } = useTranslation();
  const onChangeCallback = useCallback(
    (event: React.SyntheticEvent<HTMLSelectElement>) => {
      onChange(event.currentTarget.value);
      onBlur && onBlur();
    },
    [onChange, onBlur],
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Tab' && onMouseEnter) {
        onMouseEnter(event);
      }
    },
    [onMouseEnter],
  );

  const handleBlur = useCallback(
    (event) => {
      if (onMouseLeave && onBlur) {
        onMouseLeave(event);
        onBlur();
      }
    },
    [onBlur, onMouseLeave],
  );

  return (
    <div className="relative flex items-center">
      {label && <Label htmlFor={name}>{label}</Label>}
      <select
        aria-describedby={`${name}-error`}
        className={cx(
          'hide-ie-arrow outline-none flex-auto pl-4 pr-10 py-3 w-full border border-gray-300 focus:border-gray-400 rounded-none appearance-none cursor-pointer transition duration-200 ease-out',
          disabled
            ? 'bg-gray-300 cursor-default text-gray-700'
            : 'bg-white cursor-text text-gray-700',
        )}
        disabled={disabled}
        id={name}
        onBlur={handleBlur}
        onChange={onChangeCallback}
        onKeyUp={handleKeyPress}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        value={value}
      >
        {nullable && (
          <option value="">
            {nullableLabel ??
              `--- ${t('translation:select.choose_option')} ---`}
          </option>
        )}
        {children}
      </select>
      {!disabled && (
        <>
          <Icon icon={['fas', 'caret-down']} />
          {placeholderRight && (
            <PlaceholderRight>{placeholderRight}</PlaceholderRight>
          )}
        </>
      )}
    </div>
  );
}
