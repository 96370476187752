import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { useQuery } from '@apollo/client';

import { ATTRIBUTES } from '../../modules/booking/customerForm/queries';
import {
  AttributesQuery,
  CategoriesQuery,
  CategoriesQuery_categories as Category,
} from '../../types/api';
import { BOOKING_PATH, SELF_SERVICE_PATH } from '../../utils/constants';
import { CATEGORIES } from '../queries';
import useFeatureFlag from '../utils/hooks/useFeatureFlag';
import useQueryParams from '../utils/hooks/useQueryParams';

import {
  ActionType,
  Flow,
  FormState,
  FormValidation,
  useBookingContext,
} from './BookingContext';

interface CategoryContextInterface {
  categorySelectionEnabled: boolean;
  categories: Category[];
  category?: Category;
  isLoading: boolean;
  onChangeCategory: (category?: Partial<Category>) => void;
}

function findCategory(categories: Category[], categoryId?: string | null) {
  return categories.find((category) => category.id === categoryId);
}

export function useCategoryContext(): CategoryContextInterface {
  const [context, dispatch] = useBookingContext();
  const { t } = useTranslation();
  const history = useHistory();
  const { categoryId, id: contextId } = useQueryParams();
  const { pathname } = useLocation();

  const bookerCategorySelectionEnabled = useFeatureFlag(
    'booker_category_selection.enabled',
  );
  const customerCategorySelectionEnabled = useFeatureFlag(
    'customer_category_selection.enabled',
  );
  const categorySelectionEnabled =
    context.flow === Flow.SELF_SERVICE
      ? customerCategorySelectionEnabled
      : bookerCategorySelectionEnabled;

  const { data: categoriesData, loading: loadingCategories } =
    useQuery<CategoriesQuery>(CATEGORIES, {
      variables: {
        selfService: context.flow === Flow.SELF_SERVICE ?? false,
        active: true,
      },
    });

  const { data: contextData, loading: loadingContext } =
    useQuery<AttributesQuery>(ATTRIBUTES, {
      variables: {
        contextId,
      },
      onError() {
        // Swallow
      },
      skip: !contextId,
    });

  const categories = categoriesData?.categories ?? [];

  async function onChangeCategory(newCategory?: Partial<Category>) {
    if (
      newCategory?.id !== undefined &&
      newCategory.id !== context.categoryId
    ) {
      dispatch({
        type: ActionType.CHANGE_CATEGORY,
        payload: newCategory.id,
      });
      dispatch({
        type: ActionType.SET_CUSTOMER_FORM_STATE,
        payload: FormState.EXPANDED,
      });
      dispatch({
        type: ActionType.SET_CUSTOMER_FORM_VALIDATION,
        payload: FormValidation.PARTIAL,
      });
    }
  }

  const getInitialCategory = useCallback(() => {
    if (
      categories?.length === 0 ||
      (contextId !== undefined && loadingContext)
    ) {
      return undefined;
    }

    const categoryFromParams = findCategory(
      categories,
      contextData?.context?.category?.id ?? categoryId,
    );

    if (categoryFromParams !== undefined) {
      return categoryFromParams;
    }

    if (!categorySelectionEnabled) {
      history.push('/error', {
        error: t('errors.category_selection_disabled'),
      });
    }

    if (categories.length === 1) {
      return categories[0];
    }

    return undefined;
  }, [categoryId, contextId, contextData, categoriesData, loadingContext]);

  useEffect(() => {
    if (
      context.categoryId == null &&
      (pathname === SELF_SERVICE_PATH || pathname === BOOKING_PATH)
    ) {
      const category = getInitialCategory();

      if (category !== undefined) {
        onChangeCategory(category).catch(console.error);
      }
    }
  }, [getInitialCategory, context]);

  return {
    categories,
    category: findCategory(categories, context.categoryId),
    categorySelectionEnabled,
    isLoading: loadingCategories || loadingContext,
    onChangeCategory,
  };
}
