import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'clsx';
import {
  GoogleApiWrapper,
  IProvidedProps,
  Map,
  Marker,
} from 'google-maps-react';
import styled from 'styled-components';

import { SecondaryButton } from '../../button';
import withGoogleConfig, {
  ConfigProps,
} from '../../utils/hoc/withGoogleConfig';
import useDebounce from '../../utils/hooks/useDebounce';
import { ErrorMessage } from '../_common/errorMessage';

import {
  AddressDetailsWithTimeZone,
  extractAddressDetails,
  getPlaceDetails,
  getPredictionResults,
  getTimezone,
  mapStatusToMessage,
} from './googleMaps';

// === Constants === //
const GOOGLE_MAP_ZOOM = 17;
const INITIAL_CENTER = {
  lat: parseFloat(
    process.env.MAP_CENTER_LAT != null && process.env.MAP_CENTER_LAT !== ''
      ? process.env.MAP_CENTER_LAT
      : '55.738156',
  ),
  lng: parseFloat(
    process.env.MAP_CENTER_LNG != null && process.env.MAP_CENTER_LNG !== ''
      ? process.env.MAP_CENTER_LNG
      : '12.398967',
  ),
};

// === Styled components === //
const SuggestionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SuggestionButton = styled(SecondaryButton)`
  min-width: 100%;
  margin-top: 0.5rem;

  &:focus {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.GREY_3 : theme.colors.WHITE};
  }
  &:focus::before {
    width: ${({ disabled }) => (disabled ? '0' : '100%')};
  }

  ${({ theme }) => theme.breakPoints.medium} {
    min-width: 0;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const MapContainer = styled.div<{ height?: number }>`
  height: 35vh;
  position: relative;
  height: ${({ height = 15 }) => `${height}rem`};
  margin-top: 1rem;
`;

const AddressMap = styled(Map)`
  position: relative !important;
`;

// === Interfaces === //
interface Props {
  disabled?: boolean;
  mapHeight?: number;
  searchString: string;
  onChangeSearchString: (searchString: string) => void;
  onChangeAddress: (address: AddressDetailsWithTimeZone) => void;
  hideSuggestions?: boolean;
  location: google.maps.LatLngLiteral;
  tabIndex?: number;
  name: string;
  onError?: () => void;
  hideMarker?: boolean;
  showMap?: boolean;
}

function GoogleMapsSearch({
  disabled = false,
  google,
  hideMarker,
  hideSuggestions = false,
  location,
  mapHeight,
  name,
  onChangeAddress,
  onChangeSearchString,
  onError,
  searchString,
  showMap = true,
  tabIndex,
}: Props & IProvidedProps & ConfigProps) {
  const { t } = useTranslation();

  const [searching, setSearching] = useState(true);
  const [queryPredictions, setQueryPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [queryError, setQueryError] = useState('');
  const addressSearchRef = useRef<HTMLInputElement>(null);
  const debouncedSearchTerm = useDebounce<string>(searchString, 500);

  const fetchPredictions = useCallback(async () => {
    if (searching && debouncedSearchTerm) {
      onChangeSearchString(debouncedSearchTerm);
      setQueryError('');

      try {
        const result = await getPredictionResults(google, searchString);
        setQueryPredictions(result);
      } catch (error) {
        setQueryError(mapStatusToMessage(error));
        onError && onError();
      }
    }
  }, [searching, debouncedSearchTerm]);

  const fetchDetails = useCallback(
    async (placeId: string) => {
      const { address_components, geometry } = await getPlaceDetails(
        google,
        placeId,
      );
      const location = {
        lat: geometry?.location?.lat() ?? 0,
        lng: geometry?.location?.lng() ?? 0,
      };
      const timezone = await getTimezone(location);
      const address = extractAddressDetails(address_components);

      return onChangeAddress({
        ...address,
        location,
        timezone: timezone?.timeZoneId,
      });
    },
    [google, onChangeAddress],
  );

  const handleSearchStringChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeSearchString(event.target.value);
    },
    [onChangeSearchString],
  );

  useEffect(() => {
    if (disabled) {
      return;
    }
    if (!searchString) {
      setQueryPredictions([]);
      setQueryError('');
    }

    fetchPredictions();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (!disabled) {
      addressSearchRef?.current?.focus();
    }
  }, [addressSearchRef, disabled]);

  return (
    <>
      <input
        className={cx(
          'outline-none mt-2 p-3 w-full leading-normal border border-gray-300 focus:border-gray-500 cursor-pointer transition duration-200 ease-out',
          disabled
            ? 'bg-gray-300 cursor-default text-gray-700'
            : 'bg-white cursor-text text-gray-700',
        )}
        disabled={disabled}
        id={name}
        onChange={handleSearchStringChange}
        onFocus={() => setSearching(true)}
        placeholder={t(
          `booking:customer_form.address_modal.search_placeholder`,
        )}
        ref={addressSearchRef}
        tabIndex={tabIndex}
        value={searchString}
      />
      <SuggestionsContainer>
        {searchString &&
          !hideSuggestions &&
          (queryError ? (
            <ErrorMessage>
              {t(`booking:customer_form.address_modal.${queryError}`)}
            </ErrorMessage>
          ) : (
            searchString &&
            (queryPredictions ?? []).map(({ description, place_id }) => (
              <SuggestionButton
                key={place_id}
                onBlur={() => {
                  setSearching(true);
                }}
                onClick={() => {
                  fetchDetails(place_id);
                  onChangeSearchString(description);
                  setQueryPredictions([]);
                  setSearching(false);
                }}
                onFocus={() => {
                  setSearching(false);
                }}
                onMouseEnter={() => {
                  setSearching(false);
                }}
                onMouseLeave={() => {
                  setSearching(true);
                }}
                tabIndex={tabIndex}
                type="button"
              >
                <span className="overflow-ellipsis whitespace-no-wrap overflow-hidden">
                  {description}
                </span>
              </SuggestionButton>
            ))
          ))}
      </SuggestionsContainer>
      {showMap && (
        <MapContainer height={mapHeight}>
          <AddressMap
            center={location}
            fullscreenControl={false}
            google={google}
            initialCenter={INITIAL_CENTER}
            mapTypeControl={false}
            streetViewControl={false}
            zoom={GOOGLE_MAP_ZOOM}
          >
            {!hideMarker && location && <Marker position={location} />}
          </AddressMap>
        </MapContainer>
      )}
    </>
  );
}

export default withGoogleConfig(
  GoogleApiWrapper((props) => ({
    apiKey: props.apiKey,
    language: props.language,
    region: props.region,
  }))(GoogleMapsSearch),
);
