import React, { ComponentType } from 'react';

import { config, country } from '../../../config/config';
import { getLocale } from '../locale';

export interface ConfigProps {
  apiKey: string;
  language: string;
  region: string;
}

export default function withGoogleConfig(
  WrappedComponent: ComponentType<ConfigProps>,
) {
  return function Wrapper(props: any) {
    const locale = getLocale();

    return (
      <WrappedComponent
        {...props}
        apiKey={config.googleApiKey || ''}
        language={locale}
        region={country}
      />
    );
  };
}
