import { useTranslation, UseTranslationResponse } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { SELF_SERVICE_PATH } from '../../../utils/constants';

import { Module } from './useUpdateTranslations';

export function useScopedTranslation(): UseTranslationResponse {
  const { pathname } = useLocation();
  const isSelfService = pathname === SELF_SERVICE_PATH;

  return useTranslation(isSelfService ? Module.SELF_SERVICE : Module.BOOKING);
}
