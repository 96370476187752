import React, { ReactElement, useState } from 'react';
import { useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/client';

import { execFetchField } from '../../types/api';
import { isEmpty } from '../../utils/object';
import Icon from '../icon/Icon';

import { TextInputFieldProps } from './_common/types';
import { EXEC_FETCH_FIELD } from './queries';
import StringField from './StringField';

interface Props extends TextInputFieldProps {
  categoryFieldConfigurationId: string;
}

enum State {
  IDLE,
  MATCH = 'match',
  NO_MATCH = 'no_match',
  SEARCHING = 'searching',
}

const ICON_MAP: Record<State, ReactElement | null> = {
  [State.IDLE]: null,
  [State.MATCH]: <Icon icon="check-circle" />,
  [State.NO_MATCH]: <Icon icon="times-circle" />,
  [State.SEARCHING]: <Icon icon="spinner" />,
};

function Addon(props: { state: State }) {
  const { state } = props;
  const { t } = useTranslation();

  if (state === State.IDLE) {
    return null;
  }

  return (
    <div className="bottom-2 absolute right-6 flex flex-row items-center -mt-10 text-gray-400 text-sm">
      {ICON_MAP[state]}
      {t(state)}
    </div>
  );
}

export function FetchField(props: Props) {
  const { categoryFieldConfigurationId, name, onChange, ...rest } = props;

  const [state, setState] = useState(State.IDLE);
  const form = useForm();

  const [fetchData] = useLazyQuery<execFetchField>(EXEC_FETCH_FIELD, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setTimeout(() => {
        const responseObject = JSON.parse(data.execFetchField);

        if (isEmpty(responseObject)) {
          return setState(State.NO_MATCH);
        }

        form.batch(() => {
          Object.entries(responseObject).forEach(([id, value]) =>
            form.change(id, value),
          );
        });

        return setState(State.MATCH);
      }, 500);
    },
    onError() {
      return setState(State.NO_MATCH);
    },
  });

  function handleChange(value: string | undefined) {
    setState(State.IDLE);
    onChange(value);

    if (form.getFieldState(name!)?.valid) {
      setState(State.SEARCHING);
      fetchData({
        variables: {
          categoryFieldConfigId: categoryFieldConfigurationId,
          value,
        },
      });
    }
  }

  return (
    <div className="relative">
      <Addon state={state} />
      <StringField {...rest} name={name} onChange={handleChange} type="text" />
    </div>
  );
}
