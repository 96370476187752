import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface Props {
  infoMessage?: String;
}
const StyledInfoMessage = styled.p`
  width: 200px;
  min-width: 70px;
  max-width: 280px
  right: 120%
`;

export default function InputHelperText({ infoMessage }: Props) {
  return (
    <label className="group relative block mb-2 mt-3 text-gray-400 select-none">
      {infoMessage && (
        <>
          <StyledInfoMessage className="absolute bottom-0 group-hover:block hidden p-2 text-center text-gray-600 text-xs border border-gray-300">
            {infoMessage}
          </StyledInfoMessage>
          <FontAwesomeIcon icon={['fas', 'info-circle']} />
        </>
      )}
    </label>
  );
}
