import React, { PropsWithChildren, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import styled, { ThemeProvider } from 'styled-components';

import { useBookingContext } from '../../_common/context/BookingContext';
import { BRAND_INFORMATION } from '../../_common/queries';
import Spinner, { SpinnerSizes } from '../../_common/Spinner';
import { FEATURE_FLAGS } from '../../_common/utils/hooks/useFeatureFlag/queries';
import { BrandInformationQuery, FeatureFlagQuery } from '../../types/api';
import generateTheme from '../theme';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function ({ children }: PropsWithChildren<{}>) {
  const [context] = useBookingContext();

  const { data: brandInformationData, loading: brandInformationLoading } =
    useQuery<BrandInformationQuery>(BRAND_INFORMATION, {
      variables:
        context?.categoryId != null
          ? { categoryId: context.categoryId }
          : undefined,
    });

  const { loading: featureFlagsLoading } = useQuery<FeatureFlagQuery>(
    FEATURE_FLAGS,
    {
      variables: { userControllable: null },
    },
  );

  const theme = useMemo(
    () => generateTheme(brandInformationData?.brandInformation),
    [brandInformationData?.brandInformation],
  );
  const loading = featureFlagsLoading || brandInformationLoading;

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <LoadingContainer>
          <Spinner size={SpinnerSizes.LARGE} />
        </LoadingContainer>
      ) : (
        <>{children}</>
      )}
    </ThemeProvider>
  );
}
