import { GoogleAPI } from 'google-maps-react';

import { config, country } from '../../../config/config';

/**
 * OK indicates that no errors occurred and at least one result was returned.
 * ZERO_RESULTS indicates that the search was successful but returned no results.
 * This may occur if the search was passed a bounds in a remote location.
 * OVER_QUERY_LIMIT indicates that you are over your quota.
 * REQUEST_DENIED indicates that your request was denied, generally because of lack of an invalid key parameter.
 * INVALID_REQUEST generally indicates that the input parameter is missing.
 * UNKNOWN_ERROR indicates a server-side error; trying again may be successful.
 */
type Status =
  | 'ZERO_RESULTS'
  | 'OK'
  | 'OVER_QUERY_LIMIT'
  | 'REQUEST_DENIED'
  | 'INVALID_REQUEST'
  | 'UNKNOWN_ERROR';

/** Map a status message from the Google Places API query to an appropriate
 * message
 * @param status The status received from the API call
 */
export function mapStatusToMessage(status: Status) {
  if (status === 'OK') return '';
  switch (status) {
    case 'ZERO_RESULTS':
      return 'no_results';
    default:
      return 'search_failed';
  }
}

/**
 * Provides an async function that uses the getPlacePredictions callback
 * function
 *
 * @param google The Google API object
 * @param input Required input to supply to the API call
 *
 */
export async function getPredictionResults(google: GoogleAPI, input: string) {
  const sessionToken = new google.maps.places.AutocompleteSessionToken();

  return new Promise<google.maps.places.AutocompletePrediction[]>(
    (resolve, reject) => {
      new google.maps.places.AutocompleteService().getPlacePredictions(
        { input, sessionToken, componentRestrictions: { country } },
        (predictions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }
          resolve(predictions);
        },
      );
    },
  );
}

/**
 * Provides an async function that uses the Geocoder request callback
 * function
 *
 * @param google The Google API object
 * @param input Required input to supply to the API call
 *
 */
export async function getGeocoderResult(google: GoogleAPI, input: string) {
  return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
    new google.maps.Geocoder().geocode(
      {
        address: input,
      },
      (predictions, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(status);
        }
        resolve(predictions);
      },
    );
  });
}

/**
 * Provides an async function that uses the PlacesService getDetails callback function
 * @param google The Google API object
 * @param placeId The place Id from the AutocompletePrediction
 */
export async function getPlaceDetails(
  google: GoogleAPI,
  placeId: string,
): Promise<google.maps.places.PlaceResult> {
  const sessionToken = new google.maps.places.AutocompleteSessionToken();

  return new Promise<google.maps.places.PlaceResult>((resolve, reject) => {
    new google.maps.places.PlacesService(
      document.createElement('div'), // passing an empty div because this does nothing in our current structure
    ).getDetails(
      { placeId, fields: ['address_component', 'geometry'], sessionToken },
      (details, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(status);
        }
        resolve(details);
      },
    );
  });
}

/**
 * Provides an async function that gets the timezone of given coordinates
 * @param coordinates A Google LatLng object
 */
export async function getTimezone(coordinates: { lat: number; lng: number }) {
  const timestamp = Date.now() / 1000;
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/timezone/json?location=${coordinates.lat},${coordinates.lng}&timestamp=${timestamp}&key=${config.googleApiKey}`,
  );
  const result = await response.json();

  return result;
}

export interface AddressDetails {
  house_number?: string;
  street?: string;
  postal_code?: string;
  city?: string;
  country?: string;
  country_short?: string;
  key?: string | null;
  place_name?: string;
  unit_number?: string;
  floor_number?: string;
  location?: google.maps.LatLngLiteral;
  state?: string;
  timezone?: string | null;
}

export interface AddressDetailsWithTimeZone extends AddressDetails {
  timezone: string;
}

/**
 * Extracts the address details from a GeocoderAddressComponent array
 * @param addressComponents The addressComponents array received from the PlaceResult
 */
export function extractAddressDetails(
  addressComponents: google.maps.GeocoderAddressComponent[] = [],
) {
  return addressComponents.reduce<AddressDetails>(
    (addressDetails, { long_name, short_name, types }) => {
      if (types.includes('street_number')) {
        addressDetails.house_number = long_name;
      } else if (types.includes('route')) {
        addressDetails.street = long_name;
      } else if (types.includes('postal_code')) {
        addressDetails.postal_code = long_name;
      } else if (types.includes('locality')) {
        addressDetails.city = long_name;
      } else if (types.includes('country')) {
        addressDetails.country = long_name;
        addressDetails.country_short = short_name;
      } else if (types.includes('neighborhood')) {
        addressDetails.place_name = long_name;
      } else if (types.includes('floor')) {
        addressDetails.floor_number = long_name;
      } else if (types.includes('administrative_area_level_1')) {
        addressDetails.state = long_name;
      }

      return addressDetails;
    },
    {},
  );
}

/**
 * Formats an AddressDetails object to a single line string where the details are grouped accordingly and separated by specified separator (default is new line).
 * @param addressDetails The addressDetails to format
 * @param separator The separator to place between the address lines, defaults
 * to new line
 * @example
 * 'Lokerenveldstraat 57, 9300 Aalst, Belgium'
 */
export function mapAddressDetailsToString(
  {
    city = '',
    country = '',
    floor_number = '',
    house_number = '',
    place_name = '',
    postal_code = '',
    street = '',
    unit_number = '',
  }: AddressDetails,
  separator = '\n',
) {
  const floorNumber =
    floor_number.length > 0
      ? floor_number.padStart(floor_number.length + 1)
      : '';
  const unitNumber =
    unit_number.length > 0 ? unit_number.padStart(unit_number.length + 1) : '';
  const placeName =
    place_name.length > 0
      ? place_name.padStart(place_name.length + separator.length, separator)
      : '';

  const addressLine = `${street} ${house_number}${floorNumber}${unitNumber}`;

  return `${addressLine}${placeName}${separator}${postal_code} ${city}${separator}${country}`.replace(
    /  +/g,
    ' ',
  );
}

/**
 * Geocodes address string to fetch extra information.
 * @param addressString address as string
 * @returns country_short, location and timeZoneId
 */
export async function getLocationInformation(addressString: string) {
  try {
    const result = await getGeocoderResult(google, addressString);
    const { country_short } = extractAddressDetails(
      result[0].address_components,
    );
    const location = {
      lat: result[0]?.geometry?.location?.lat(),
      lng: result[0]?.geometry?.location?.lng(),
    };
    const { timeZoneId } = await getTimezone(location);

    return {
      country_short,
      location,
      timezone: timeZoneId,
    };
  } catch (e) {
    return {};
  }
}

export function mapAddressDetailsToSkedifyAddress(address: AddressDetails) {
  return {
    city: address.city,
    countryCode: address.country_short,
    coordinates: address.location,
    houseNumber: address.house_number,
    floorNumber: address.floor_number,
    postalCode: address.postal_code,
    street: address.street,
    state: address.state,
    unitNumber: address.unit_number,
  };
}
