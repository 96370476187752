import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useQuery } from '@apollo/client';

import { FeatureFlagQuery, FeatureFlagSetting } from '../../../../types/api';
import { SELF_SERVICE_PATH } from '../../../../utils/constants';

import { FEATURE_FLAG_SETTING, FEATURE_FLAGS } from './queries';

/*
 * Attempts to get the provided feature flag key's value.
 *
 * @param {string} key
 * @param {boolean} fallback
 * @returns {boolean} feature flag value value
 */
export default function useFeatureFlag(key: string, fallback = false) {
  const { data } = useQuery<FeatureFlagQuery>(FEATURE_FLAGS, {
    variables: { userControllable: null },
  });

  const ff = useMemo(() => {
    const featureFlags = data?.featureFlags ?? [];

    return featureFlags.find((ff) => ff.key === key);
  }, [key, data]);

  return ff?.enabled ?? fallback;
}

export function useFeatureFlagSetting(featureFlagSettingKey: string) {
  const { data } = useQuery<FeatureFlagSetting>(FEATURE_FLAG_SETTING, {
    variables: { featureFlagSettingKey },
  });

  return data?.featureFlagSetting.value;
}

export function useCategorySelectionFeatureFlag() {
  const { pathname } = useLocation();

  const bookerCategorySelectionEnabled = useFeatureFlag(
    'booker_category_selection.enabled',
  );
  const customerCategorySelectionEnabled = useFeatureFlag(
    'customer_category_selection.enabled',
  );

  return pathname === SELF_SERVICE_PATH
    ? customerCategorySelectionEnabled
    : bookerCategorySelectionEnabled;
}
